import * as Sentry from '@sentry/browser';
import { isAxiosError } from 'axios';
import { config } from '../config/config';

export function startErrorLog() {
  if (config.services.sentry.dsn) {
    Sentry.init({
      integrations: [
        Sentry.thirdPartyErrorFilterIntegration({
          filterKeys: ['web-lite'],
          behaviour: 'drop-error-if-contains-third-party-frames',
        }),
      ],

      environment: config.services.sentry.environment,
      dsn: config.services.sentry.dsn,
      ignoreErrors: [
        /**
         * Thrown when firefox prevents an add-on from refrencing a DOM element that has been removed.
         * This can also be filtered by enabling the browser extension inbound filter
         */
        "TypeError: can't access dead object",

        /**
         * React internal error thrown when something outside react modifies the DOM
         * This is usually because of a browser extension or Chrome's built-in translate
         */
        "NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
        "NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.",

        'AxiosError',
      ],
      beforeSend(event, hint) {
        const error = hint.originalException;

        if (isAxiosError(error)) {
          return null;
        }

        return event;
      },
    });
  }
}
